import { Box, BoxProps } from '@chakra-ui/react';
import Image, { StaticImageData } from 'next/image';
import { FC } from 'react';

interface IChakraNextResponsiveImageProps extends BoxProps {
  src: StaticImageData | string;
  alt: string;
  sizes?: string;
  style?: Record<string, string>;
  priority?: boolean;
}

export const ChakraNextResponsiveImage: FC<IChakraNextResponsiveImageProps> = ({
  src,
  alt,
  sizes,
  style,
  priority,
  ...rest
}) => {
  return (
    <Box position="relative" {...rest}>
      {src && <Image fill src={src} alt={alt} sizes={sizes} style={style} priority={priority} />}
    </Box>
  );
};

import {
  Button,
  Menu,
  MenuButton,
  Placement,
  Portal,
  LayoutProps,
  Text,
  MenuList,
  MenuItem,
  Box,
  Icon,
  Flex,
} from '@chakra-ui/react';
import i18next from 'i18next';
import { FC } from 'react';

import ChevronDownIcon from '@/assets/icons/ic-caret-down.svg';
import CheckMarkIcon from '@/assets/icons/ic-check-mark.svg';

interface IRolesOptionProps {
  id: string;
  value: string;
}

const rolesOption: Array<IRolesOptionProps> = [
  {
    id: 'professor',
    value: i18next.t('librarians.contactForm.institutionalRole.roles.0'),
  },
  {
    id: 'postdoc',
    value: i18next.t('librarians.contactForm.institutionalRole.roles.1'),
  },
  {
    id: 'student',
    value: i18next.t('librarians.contactForm.institutionalRole.roles.2'),
  },
  {
    id: 'researcher',
    value: i18next.t('librarians.contactForm.institutionalRole.roles.3'),
  },
  {
    id: 'librarian',
    value: i18next.t('librarians.contactForm.institutionalRole.roles.4'),
  },
  {
    id: 'other',
    value: i18next.t('librarians.contactForm.institutionalRole.roles.5'),
  },
];

export interface IDropdownSelect extends LayoutProps {
  value: string;
  placement?: Placement;
  placeholder: string;

  onChange: (...event: Array<any>) => void;
}

export const LibrariansRoleDropdown: FC<IDropdownSelect> = ({ value, placement, onChange, placeholder, ...rest }) => {
  return (
    <Menu placement={placement} isLazy matchWidth>
      <MenuButton
        as={Button}
        size="md"
        h={12}
        variant="menu"
        rightIcon={<ChevronDownIcon />}
        minW="100%"
        py={2}
        _hover={{ backgroundColor: 'none' }}
        _active={{ backgroundColor: 'none' }}
        {...rest}
      >
        <Text noOfLines={1} fontFamily="body" fontWeight="normal" color="gray.600">
          {value || placeholder}
        </Text>
      </MenuButton>
      <Portal>
        <MenuList overflow="auto" minW="100%" zIndex="popover">
          {rolesOption.map((option, index) => (
            <MenuItem
              key={option.id}
              onClick={() => onChange(option.value)}
              fontFamily="body"
              fontWeight="normal"
              color="gray.600"
              _hover={{ backgroundColor: 'violet.500', color: 'white' }}
            >
              <Flex w="100%" justify="space-between">
                <Text noOfLines={1}>{option.value}</Text>
                <Box>{value === option.value && <Icon as={CheckMarkIcon} color="violet.500" ml="auto" />}</Box>
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};

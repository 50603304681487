import { Box, Button, Container, Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import EmailIcon from '@/assets/icons/ic-share-email-40.svg';

import { Section } from '@/components/ui/core/Section/Section';
import { LibrariansContactForm } from '@/components/ui/features/librarians/LibrariansContactForm/LibrariansContactForm';

interface IContactUsProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}
export const ContactUs: FC<IContactUsProps> = ({ isOpen, onOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Section variant="banner" textAlign="center" py={{ base: 14, md: 20 }}>
        <Container>
          <Heading
            maxW={{ base: 'lg', lg: '56rem' }}
            px="4"
            mx="auto"
            fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}
            lineHeight={{ base: '44px', lg: '60px' }}
            pb={14}
          >
            {t('librarians.banner.title')}
          </Heading>
          <Button rightIcon={<EmailIcon width={25} />} colorScheme="black" textTransform="capitalize" onClick={onOpen}>
            {t('librarians.banner.buttonText')}
          </Button>
        </Container>
      </Section>
      <LibrariansContactForm isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

import { chakra, forwardRef, ThemingProps, omitThemingProps, useStyleConfig, HTMLChakraProps } from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';

export interface ISectionProps extends HTMLChakraProps<'section'>, ThemingProps {}

export const Section = forwardRef<ISectionProps, 'section'>(function Section(props, ref) {
  const styles = useStyleConfig('Section', props);

  const { ...rest } = omitThemingProps(props);

  return <chakra.section ref={ref} __css={styles} {...rest} />;
});

if (__DEV__) {
  Section.displayName = 'Section';
}

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  FormErrorMessage,
  VStack,
  useBreakpointValue,
  Text,
} from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LibrariansRoleDropdown } from '@/components/ui/features/librarians/LibrariansContactForm/components/LibrariansRoleDropdown';
import { IToastType } from '@/enums/ToastType';
import { useStores } from '@/hooks/useStores';
import { useToast } from '@/hooks/useToast';

interface ILibrarianProps {
  name: string;
  email: string;
  institution: string;
  institutionalRole: string;
  informationTopics: Array<string>;
}

interface ILibrariansContactFormProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LibrariansContactForm: FC<ILibrariansContactFormProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { store } = useStores();
  const toast = useToast();
  const isCentered = useBreakpointValue({ base: false, md: true });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm<ILibrarianProps>();

  const onSubmit = useCallback(async (formData) => {
    try {
      await store.request(`contact`, 'POST', {
        data: {
          type: 'contact',
          attributes: {
            type: 'librarian',
            ...formData,
          },
        },
      });

      toast({ status: IToastType.SUCCESS, title: t('librarians.contactForm.successfulSubmission') });
    } catch (e) {
      toast({ status: IToastType.ERROR, title: t('librarians.contactForm.errorSubmit') });
    } finally {
      reset();
      onClose();
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent px={{ base: 2, sm: 6, lg: 12 }} py={10}>
        <ModalCloseButton fontSize={{ base: 'md', lg: 'xl' }} m={4} />
        <ModalHeader fontSize={{ base: '2xl', lg: '3xl' }} lineHeight={9} fontWeight="bold" mb={2}>
          {t('librarians.contactForm.title')}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Text mb={10}>{t('librarians.contactForm.description')}</Text>
            <VStack gap="10">
              <FormControl variant="floating" isInvalid={Boolean(errors.name)}>
                <Input
                  placeholder=" "
                  {...register('name', { required: true })}
                  variant="flushed"
                  errorBorderColor="none"
                />
                <FormLabel>{t('librarians.contactForm.name.label')}*</FormLabel>
                <FormErrorMessage>{t('librarians.contactForm.name.required')}</FormErrorMessage>
              </FormControl>

              <FormControl variant="floating" isInvalid={Boolean(errors.institution)}>
                <Input
                  placeholder=" "
                  {...register('institution', { required: true })}
                  variant="flushed"
                  errorBorderColor="none"
                />
                <FormLabel>{t('librarians.contactForm.institution.label')}*</FormLabel>
                <FormErrorMessage>{t('librarians.contactForm.institution.required')}</FormErrorMessage>
              </FormControl>

              <FormControl variant="floating" isInvalid={Boolean(errors.email)}>
                <Input
                  placeholder=" "
                  {...register('email', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                  errorBorderColor="none"
                  variant="flushed"
                />
                <FormLabel>{t('librarians.contactForm.email.label')}*</FormLabel>
                <FormErrorMessage>{t(`librarians.contactForm.email.${errors?.email?.type}`)}</FormErrorMessage>
              </FormControl>
              <Controller
                control={control}
                name="institutionalRole"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl isInvalid={Boolean(errors.institutionalRole)}>
                    <LibrariansRoleDropdown
                      placement="bottom-end"
                      placeholder={`${t('librarians.contactForm.institutionalRole.label')}*`}
                      {...field}
                    />
                    <FormErrorMessage>{t('librarians.contactForm.institutionalRole.required')}</FormErrorMessage>
                  </FormControl>
                )}
              />
            </VStack>
          </ModalBody>

          <ModalFooter mt={5} justifyContent="flex-start">
            <Button isLoading={isSubmitting} type="submit" minW={40}>
              {t('librarians.contactForm.submit')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

import { AspectRatio, Box, BoxProps, ResponsiveValue } from '@chakra-ui/react';
import { FC } from 'react';

import PlayerBg from '@/assets/images/landing/player-bg.png';

import { ChakraNextResponsiveImage } from '@/components/ui/shared/image/ChakraNextResponsiveImage';
import { VideoJS } from '@/components/ui/shared/video/VideoJS/VideoJS';

const VERTICAL_OFFSET = '40px';
const HORIZONTAL_OFFSET: ResponsiveValue<string> = {
  base: '30px',
  md: '60px',
};

export const OriginalsPromoVideo: FC<BoxProps> = (props) => {
  return (
    <Box {...props}>
      <Box pl={HORIZONTAL_OFFSET} pb={VERTICAL_OFFSET} pos="relative">
        <ChakraNextResponsiveImage
          src={PlayerBg}
          width={{
            base: '285px',
            md: '380px',
          }}
          height={{
            base: '172px',
            md: '231px',
          }}
          alt="librarians"
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          pos="absolute"
          top={VERTICAL_OFFSET}
          right={HORIZONTAL_OFFSET}
          width={{
            base: '285px',
            md: '380px',
          }}
          height={{
            base: '165px',
            md: '231px',
          }}
        >
          <AspectRatio maxW="full" ratio={16 / 9} flexGrow={1}>
            <Box borderRadius="20px" border="10px solid rgba(255, 255, 255, 0)" backdropFilter="blur(17.5px)">
              <VideoJS
                data-peer
                title="Underline Originals"
                options={{
                  sources: [
                    {
                      src: '/videos/underlineTrailer.mp4',
                      type: 'video/mp4',
                    },
                  ],
                  loop: true,
                  poster: '/images/librarians/player-bg-main.webp',
                  controlBar: false,
                }}
                crossOrigin="anonymous"
              />
            </Box>
          </AspectRatio>
        </Box>
      </Box>
    </Box>
  );
};

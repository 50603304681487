import { AspectRatio, Skeleton } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import { IVideoJSProps } from '@/hooks/videojs/type';

const VideoJSCore = dynamic(
  () => import('@/components/ui/shared/video/VideoJS/VideoJSCore').then((mod) => mod.VideoJSCore),
  {
    loading: () => (
      <AspectRatio ratio={16 / 9} w="full">
        <Skeleton h="full" w="full" />
      </AspectRatio>
    ),
    ssr: false,
  }
);

export const VideoJS: FC<IVideoJSProps> = (iVideoProps) => {
  return <VideoJSCore {...iVideoProps} />;
};

export enum AccessFrom {
  /**
   * Accessed through the events subdomain events.underline.io
   */
  Events = 'events',

  /**
   * Accessed through the subdomain ex. {abbreviation}.underline.io
   */
  Publisher = 'publisher',

  Hubs = 'hubs',
  /**
   * Accessed through the path ex. underline.io or underline.io/something
   */
  Core = 'core',
}

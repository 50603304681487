import { Box, BoxProps, Heading, Container, AspectRatio, Center } from '@chakra-ui/react';
import NextImage, { StaticImageData } from 'next/image';
import { FC, ReactNode } from 'react';

export const CoreHeroTitle: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Heading
      color="white"
      fontSize={{ base: '40px', lg: '64px' }}
      lineHeight={{ base: '48px', lg: '90px' }}
      p={{ base: '64px 0 56px', lg: '90px 0 40px' }}
      textAlign="left"
      {...rest}
    >
      {children}
    </Heading>
  );
};

export const CoreHeroBody: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Container m={{ base: '0', lg: '0 auto' }} position="relative" boxSizing="border-box" {...rest}>
      {children}
    </Container>
  );
};

interface ICoreHero extends BoxProps {
  src?: string | StaticImageData;
  children: ReactNode;
}
export const CoreHeroWithImage: FC<ICoreHero> = ({ src, children, ...rest }) => {
  return (
    <Center position="relative">
      <Box mt="-16" position="absolute" w="full" bgColor="black" overflow="hidden" bg="gradientBackground2" {...rest}>
        <AspectRatio mx="auto" objectFit="cover" h="610px" w="full" ratio={1440 / 596}>
          <NextImage quality={90} layout="fill" priority={true} src={src} alt="Hero cover" />
        </AspectRatio>
      </Box>
      {children}
    </Center>
  );
};

export const CoreHero: FC<ICoreHero> = ({ children, ...rest }) => {
  return (
    <Center position="relative">
      <Box position="absolute" w="full" h="full" bgColor="black" overflow="hidden" bg="gradientBackground2" {...rest} />
      {children}
    </Center>
  );
};

export const CoreHubsHero: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box>
      <Box
        backgroundColor="black"
        position="relative"
        backgroundSize="cover"
        backgroundPosition="bottom"
        mt={-16}
        pt={16}
        {...rest}
      >
        {children}
      </Box>
      <Box h="152px" backgroundImage="linear-gradient(180deg, #000 0%, rgba(55, 55, 55, 0.00) 100%)" />
    </Box>
  );
};

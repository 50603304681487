import dynamic from 'next/dynamic';
import { FC, ReactNode } from 'react';

import { ICoreHeaderProps } from '@/components/ui/shared/headers/CoreHeader/CoreHeader';
import { AccessFrom } from '@/enums/AccessFrom';

const EventsSubdomain = dynamic(() =>
  import('@/components/ui/shared/layouts/EventsLayout/EventsLayout').then((mod) => mod.EventsLayout)
);

const PublisherLayout = dynamic(() =>
  import('@/components/ui/shared/layouts/PublisherLayout/PublisherLayout').then((mod) => mod.PublisherLayout)
);

const HubsLayout = dynamic(() =>
  import('@/components/ui/shared/layouts/HubsLayout/HubsLayout').then((mod) => mod.HubsLayout)
);

const CoreLayout = dynamic(() =>
  import('@/components/ui/shared/layouts/CoreLayout/CoreLayout').then((mod) => mod.CoreLayout)
);

interface ILayoutWrapperProps extends ILayoutProps {
  accessFrom?: AccessFrom;
  children: ReactNode;
}

export interface ILayoutProps {
  displayFooter?: boolean;
  beforeChildren?: ReactNode;
  navigationVariant?: ICoreHeaderProps['variant'];
  blackLogo?: boolean; //only for events
  children: ReactNode;
}

export const Layout: FC<ILayoutWrapperProps> = ({ accessFrom = AccessFrom.Core, children, ...rest }) => {
  if (accessFrom === AccessFrom.Events) {
    return <EventsSubdomain {...rest}>{children}</EventsSubdomain>;
  }

  if (accessFrom === AccessFrom.Hubs) {
    return <HubsLayout {...rest}>{children}</HubsLayout>;
  }

  if (accessFrom === AccessFrom.Publisher) {
    return <PublisherLayout {...rest}>{children}</PublisherLayout>;
  }

  return <CoreLayout {...rest}>{children}</CoreLayout>;
};

import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

export const BlurredStain: FC<BoxProps> = (props) => {
  return (
    <Box
      position="absolute"
      w="664px"
      h="635px"
      tabIndex={-1}
      backgroundColor="#BFA8F7"
      opacity="0.5"
      filter="blur(67.957px)"
      {...props}
    />
  );
};
